/* Generated by ParaType (http://www.paratype.com)*/

/* Font Golos Text: Copyright ? ParaType, 2018. All rights reserved.*/

@font-face {
  font-weight: 300;
  font-family: "GolosText";
  font-style: normal;
  src: local("GolosText"), url("./Golos-Text_VF.woff2") format("woff2"),
    url("./Golos-Text_VF.woff") format("woff");
}
